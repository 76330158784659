import React, { useEffect, useState } from "react";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";
import {
  TimestreamQueryClient,
  QueryCommand,
} from "@aws-sdk/client-timestream-query";
import MQTTDashboard from "./MQTTDashboard";
import MachineDashboard from "./MachineDashboard";
import ConsumerGoodsDashboard from "./ConsumerGoodsDashboard";
const App = () => {
  const [temperatureData, setTemperatureData] = useState([]);
  const [currentTemp, setCurrentTemp] = useState(null);
  const [error, setError] = useState(null);

  const timestreamClient = new TimestreamQueryClient({
    region: "us-east-1",
    credentials: {
      accessKeyId: "AKIA3LET6GOC2WEU2DWR",
      secretAccessKey: "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",
    }
  });

  const fetchData = async () => {
    const query = `
      SELECT time, measure_value::double as temperature
      FROM "nommas_data"."temperature"
      WHERE measure_name = 'sensor_value'
        AND time > ago(15m)
      ORDER BY time ASC
    `;

    try {
      const queryCommand = new QueryCommand({ QueryString: query });
      const result = await timestreamClient.send(queryCommand);

      if (result.Rows && result.Rows.length > 0) {
        // First, let's log the structure to debug
        console.log("First row structure:", JSON.stringify(result.Rows[0], null, 2));
        
        const formattedData = result.Rows.map(row => {
          // Find the indices for time and temperature in the row data
          const timeIndex = result.ColumnInfo.findIndex(col => col.Name === 'time');
          const tempIndex = result.ColumnInfo.findIndex(col => col.Name === 'temperature');
          
          if (timeIndex === -1 || tempIndex === -1) {
            console.warn("Could not find required columns", { timeIndex, tempIndex });
            return null;
          }

          const timestamp = row.Data[timeIndex].ScalarValue;
          const temp = row.Data[tempIndex].ScalarValue;
          
          return {
            time: new Date(timestamp).toLocaleTimeString(),
            temperature: temp ? parseFloat(temp) : null
          };
        }).filter(item => item !== null && item.temperature !== null);

        // Update current temperature with the latest reading
        if (formattedData.length > 0) {
          setCurrentTemp(formattedData[formattedData.length - 1].temperature);
        }

        setTemperatureData(formattedData);
        setError(null);
        
        // Log the formatted data for debugging
        console.log("Formatted data:", formattedData);
      } else {
        console.warn("No data returned from query");
      }
    } catch (err) {
      console.error("Error querying Timestream:", err);
      setError(err.message);
    }
  };

  useEffect(() => {
    fetchData();
    const interval = setInterval(fetchData, 5000); // Update every 5 seconds
    return () => clearInterval(interval);
  }, []);

  return (
  //   <div className="min-h-screen bg-gray-100 p-6">
  //     <div className="max-w-6xl mx-auto">
  //       <div className="bg-white shadow-lg rounded-lg p-6 mb-6">
  //         <div className="flex justify-between items-center mb-6">
  //           <h1 className="text-2xl font-bold text-gray-800">Temperature Data Visualization</h1>
  //           {currentTemp !== null && (
  //             <div className="bg-blue-50 p-4 rounded-lg">
  //               <p className="text-sm text-blue-600 font-medium">Current Temperature</p>
  //               <p className="text-3xl font-bold text-blue-700">{currentTemp.toFixed(1)}°C</p>
  //             </div>
  //           )}
  //         </div>

  //         {error ? (
  //           <div className="text-red-500 p-4 rounded-lg bg-red-50 border border-red-200">
  //             <p>{error}</p>
  //           </div>
  //         ) : temperatureData.length === 0 ? (
  //           <div className="text-gray-500 text-center py-8">Loading data...</div>
  //         ) : (
  //           <div className="h-96">
  //             <ResponsiveContainer width="100%" height="100%">
  //               <LineChart
  //                 data={temperatureData}
  //                 margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
  //               >
  //                 <CartesianGrid strokeDasharray="3 3" />
  //                 <XAxis 
  //                   dataKey="time"
  //                   label={{ value: 'Time', position: 'bottom' }}
  //                 />
  //                 <YAxis 
  //                   domain={['auto', 'auto']}
  //                   label={{ 
  //                     value: 'Temperature (°C)', 
  //                     angle: -90, 
  //                     position: 'insideLeft',
  //                     style: { textAnchor: 'middle' }
  //                   }}
  //                 />
  //                 <Tooltip 
  //                   contentStyle={{ backgroundColor: 'rgba(255, 255, 255, 0.9)' }}
  //                   formatter={(value) => [`${value.toFixed(1)}°C`, 'Temperature']}
  //                 />
  //                 <Legend />
  //                 <Line 
  //                   type="monotone" 
  //                   dataKey="temperature" 
  //                   stroke="#2563eb"
  //                   strokeWidth={2}
  //                   dot={false}
  //                   activeDot={{ r: 8 }}
  //                   name="Temperature"
  //                   isAnimationActive={false}
  //                 />
  //               </LineChart>
  //             </ResponsiveContainer>
  //           </div>
  //         )}
  //       </div>
  //     </div>
  //   </div>
  <ConsumerGoodsDashboard/> );
};

export default App;