// src/MQTTDashboard.js
import React, { useState, useEffect } from 'react';
import mqtt from 'mqtt';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Activity, AlertCircle, Database, Wifi, WifiOff, Thermometer } from 'lucide-react';

const MQTTDashboard = () => {
  const [messages, setMessages] = useState([]);
  const [status, setStatus] = useState('Connecting...');
  const [chartData, setChartData] = useState([]);
  const [timeRange, setTimeRange] = useState('1h');

  useEffect(() => {
    let mqttClient;
    try {
      // Connect to HiveMQ public broker with WebSocket
      mqttClient = mqtt.connect('wss://broker.hivemq.com:8884', {
        clientId: 'dashboard_' + Math.random().toString(16).substr(2, 8)
      });
      
      mqttClient.on('connect', () => {
        setStatus('Connected');
        console.log('Connected to MQTT broker');

        // Subscribe to various public topics that provide real sensor data
        const topics = [
          'iot/sensor/temperature',      // Temperature sensors
          'demo/sensor/#',               // Various sensor data
          'nodered/temperature',         // Node-RED temperature data
          'sensor/temperature',          // General temperature sensors
          'weather/temperature',         // Weather stations
          'factory/machine/temp',        // Factory sensors
          '/esp32/temperature',          // ESP32 devices
          'home/sensors/#'               // Home automation sensors
        ];

        topics.forEach(topic => {
          mqttClient.subscribe(topic, (err) => {
            if (err) console.error('Subscription error:', err);
          });
        });

        // Also publish some test data to check connection
        mqttClient.publish('iot/sensor/temperature', JSON.stringify({
          temperature: 23.5,
          timestamp: new Date().toISOString()
        }));
      });

      mqttClient.on('message', (topic, message) => {
        try {
          const timestamp = new Date().toISOString();
          let temperature = null;
          
          // Try to parse the message and extract temperature data
          try {
            const payload = JSON.parse(message.toString());
            
            // Handle different message formats
            temperature = payload.temperature || 
                         payload.temp || 
                         payload.value || 
                         payload.data?.temperature ||
                         (typeof payload === 'number' ? payload : null);

            if (temperature !== null) {
              const newDataPoint = {
                time: new Date().toLocaleTimeString(),
                timestamp: new Date().getTime(),
                value: parseFloat(temperature),
                topic
              };

              setChartData(prev => {
                const newData = [...prev, newDataPoint];
                return newData.slice(-180); // Keep last 180 points
              });

              setMessages(prev => [
                ...prev.slice(-50),
                { topic, payload: JSON.stringify(payload), timestamp }
              ]);
            }
          } catch (e) {
            // If not JSON, try to parse as number
            const numValue = parseFloat(message.toString());
            if (!isNaN(numValue)) {
              const newDataPoint = {
                time: new Date().toLocaleTimeString(),
                timestamp: new Date().getTime(),
                value: numValue,
                topic
              };

              setChartData(prev => {
                const newData = [...prev, newDataPoint];
                return newData.slice(-180);
              });

              setMessages(prev => [
                ...prev.slice(-50),
                { topic, payload: message.toString(), timestamp }
              ]);
            }
          }
        } catch (error) {
          console.error('Error processing message:', error);
        }
      });

      mqttClient.on('error', (err) => {
        console.error('MQTT Error:', err);
        setStatus('Error: ' + err.message);
      });

      mqttClient.on('offline', () => {
        setStatus('Offline');
      });

    } catch (err) {
      console.error('MQTT Connection Error:', err);
      setStatus('Connection Error');
    }

    return () => {
      if (mqttClient) {
        mqttClient.end();
      }
    };
  }, []);

  // Rest of your component code remains the same
  const getFilteredData = () => {
    const now = Date.now();
    const ranges = {
      '1h': 60 * 60 * 1000,
      '6h': 6 * 60 * 60 * 1000,
      '24h': 24 * 60 * 60 * 1000
    };
    return chartData.filter(d => (now - d.timestamp) <= ranges[timeRange]);
  };

  const getTemperatureStatus = (temp) => {
    if (temp < 18) return { text: 'Low', color: 'text-blue-500' };
    if (temp > 25) return { text: 'High', color: 'text-red-500' };
    return { text: 'Normal', color: 'text-green-500' };
  };

  const latestTemp = chartData.length > 0 ? chartData[chartData.length - 1].value : null;
  const tempStatus = latestTemp ? getTemperatureStatus(latestTemp) : { text: 'N/A', color: 'text-gray-500' };

  // Your existing render code remains the same
  return (
    <div className="p-6 max-w-6xl mx-auto">
      {/* ... existing JSX ... */}
      <div className="flex justify-between items-center mb-6">
        <div>
          <h1 className="text-3xl font-bold">Public Sensors Monitor</h1>
          <p className="text-gray-600">Real-time data from public IoT sensors</p>
        </div>
        <div className="flex items-center gap-4">
          <div className="flex gap-2">
            {['1h', '6h', '24h'].map((range) => (
              <button
                key={range}
                onClick={() => setTimeRange(range)}
                className={`px-3 py-1 rounded ${
                  timeRange === range ? 'bg-blue-500 text-white' : 'bg-gray-200'
                }`}
              >
                {range.toUpperCase()}
              </button>
            ))}
          </div>
          <div className="flex items-center gap-2">
            {status === 'Connected' ? (
              <Wifi className="text-green-500 w-5 h-5" />
            ) : (
              <WifiOff className="text-red-500 w-5 h-5" />
            )}
            <span>{status}</span>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-6">
        <div className="bg-white p-4 rounded-lg shadow flex items-center justify-between">
          <div>
            <p className="text-gray-500">Latest Reading</p>
            <p className="text-2xl font-bold">{latestTemp ? `${latestTemp.toFixed(1)}°C` : 'N/A'}</p>
            <p className={tempStatus.color}>{tempStatus.text}</p>
          </div>
          <Thermometer className="text-blue-500 w-8 h-8" />
        </div>

        <div className="bg-white p-4 rounded-lg shadow flex items-center justify-between">
          <div>
            <p className="text-gray-500">Active Topics</p>
            <p className="text-2xl font-bold">{new Set(messages.map(m => m.topic)).size}</p>
          </div>
          <Activity className="text-purple-500 w-8 h-8" />
        </div>

        <div className="bg-white p-4 rounded-lg shadow flex items-center justify-between">
          <div>
            <p className="text-gray-500">Messages</p>
            <p className="text-2xl font-bold">{messages.length}</p>
          </div>
          <Database className="text-orange-500 w-8 h-8" />
        </div>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
        <div className="lg:col-span-2">
          <div className="bg-white rounded-lg shadow p-4 mb-6">
            <h2 className="text-xl font-bold mb-4">Sensor Data History</h2>
            <div className="h-80">
              <ResponsiveContainer width="100%" height="100%">
                <LineChart data={getFilteredData()}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis 
                    dataKey="time" 
                    interval="preserveStartEnd"
                  />
                  <YAxis 
                    domain={['auto', 'auto']}
                    label={{ value: 'Temperature (°C)', angle: -90, position: 'insideLeft' }}
                  />
                  <Tooltip 
                    formatter={(value) => [`${value.toFixed(1)}°C`, 'Temperature']}
                    labelFormatter={(label) => `Time: ${label}`}
                  />
                  <Legend />
                  <Line 
                    type="monotone" 
                    dataKey="value" 
                    stroke="#2563eb" 
                    name="Temperature"
                    dot={false}
                    strokeWidth={2}
                  />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>

        <div className="lg:col-span-1">
          <div className="bg-white rounded-lg shadow p-4">
            <h2 className="text-xl font-bold mb-4">Recent Messages</h2>
            <div className="space-y-2 max-h-[400px] overflow-y-auto">
              {messages.slice(-10).reverse().map((msg, idx) => (
                <div key={idx} className="p-3 bg-gray-50 rounded-lg">
                  <div className="flex justify-between items-start">
                    <span className="font-medium text-sm">{msg.topic}</span>
                    <span className="text-sm text-gray-500">{new Date(msg.timestamp).toLocaleTimeString()}</span>
                  </div>
                  <p className="mt-1 text-sm text-gray-700 break-all">{msg.payload}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MQTTDashboard;