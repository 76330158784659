// src/components/ConsumerGoodsDashboard.jsx
import React, { useState, useEffect } from 'react';
import mqtt from 'mqtt';
import { Card, CardContent, CardHeader, CardTitle } from   './components/ui/card';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Activity, Thermometer, Gauge, Box, Package, Cpu } from 'lucide-react';

// Add this at the top of your file, outside the component
const machineLastValues = new Map();

const getSmoothedValue = (machineId, metric, currentValue, minChange, maxChange) => {
  const key = `${machineId}-${metric}`;
  const lastValue = machineLastValues.get(key) ?? currentValue;
  
  // Generate small random change
  const change = (Math.random() - 0.5) * (maxChange - minChange) + minChange;
  let newValue = lastValue + change;
  
  // Store new value
  machineLastValues.set(key, newValue);
  return newValue;
};

const generateMachineData = (machineId) => {
  // Industrial packaging/assembly machines typically operate at:
  // Temperature: 25-35°C (room temperature plus machine heat)
  // Pressure: 5-8 bar (typical pneumatic systems)
  
  const baseTemp = getSmoothedValue(machineId, 'temp', 28, -0.1, 0.1);
  const basePressure = getSmoothedValue(machineId, 'pressure', 6, -0.05, 0.05);
  const baseSpeed = getSmoothedValue(machineId, 'speed', 1000, -5, 5);
  
  const baseData = {
    status: 'Running',
    temperature: Math.max(25, Math.min(35, baseTemp)),     // 25-35°C (realistic machine temp)
    pressure: Math.max(5, Math.min(8, basePressure)),      // 5-8 bar (standard pneumatic)
    speed: Math.max(950, Math.min(1050, baseSpeed)),       // 950-1050 RPM
    parts_produced: 5
  };

  // Calculate quality based on operating parameters
  const tempQuality = 100 - Math.abs(baseData.temperature - 28) * 4;    // Optimal at 28°C
  const pressureQuality = 100 - Math.abs(baseData.pressure - 6) * 8;    // Optimal at 6 bar
  const speedQuality = 100 - Math.abs(baseData.speed - 1000) * 0.1;    // Optimal at 1000 RPM
  
  // Quality is affected by all parameters
  const quality = Math.min(99.9, (tempQuality + pressureQuality + speedQuality) / 3);
  
  // Performance based on speed
  const performance = (baseData.speed / 1000) * 100;
  
  // Availability is always 100 since status is Running
  const availability = 100;
  
  // Calculate OEE
  const oee = (availability * performance * quality) / 10000;

  return {
    ...baseData,
    parts_total: baseData.parts_produced,
    parts_good: Math.floor(baseData.parts_produced * (quality / 100)),
    availability,
    performance,
    quality,
    oee
  };
};
const ConsumerGoodsDashboard = () => {
  const [machines, setMachines] = useState({});
  const [selectedMachine, setSelectedMachine] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [client, setClient] = useState(null);
  const [publishIntervals, setPublishIntervals] = useState({});

  const consumerGoodsProducts = {
    'Packaging Line': {
      machines: ['PKG-001', 'PKG-002', 'PKG-003', 'PKG-004'],
      color: 'blue'
    },
    'Assembly Line': {
      machines: ['ASM-001', 'ASM-002', 'ASM-003'],
      color: 'green'
    },
    'Quality Control': {
      machines: ['QC-001', 'QC-002'],
      color: 'purple'
    },
    'Finishing Line': {
      machines: ['FIN-001', 'FIN-002', 'FIN-003'],
      color: 'orange'
    }
  };

  const publishMachineData = (machineId, mqttClient) => {
    const data = generateMachineData(machineId);
    const timestamp = new Date().toISOString();

    const messages = {
      status: { status: data.status, timestamp },
      temperature: { value: data.temperature, timestamp },
      pressure: { value: data.pressure, timestamp },
      speed: { value: data.speed, timestamp },
      production: { 
        total: data.parts_total,
        good: data.parts_good,
        timestamp 
      },
      oee: {
        availability: data.availability,
        performance: data.performance,
        quality: data.quality,
        oee: data.oee,
        timestamp
      }
    };

    Object.entries(messages).forEach(([type, payload]) => {
      mqttClient.publish(`machine/${machineId}/${type}`, JSON.stringify(payload));
    });
  };

  useEffect(() => {
    const mqttClient = mqtt.connect('wss://broker.hivemq.com:8884/mqtt');
    
    mqttClient.on('connect', () => {
      console.log('Connected to MQTT broker');
      setClient(mqttClient);

      // Subscribe to all machine topics
      Object.values(consumerGoodsProducts).forEach(({ machines }) => {
        machines.forEach(machineId => {
          mqttClient.subscribe(`machine/${machineId}/#`);
        });
      });

      // Start publishing data for all machines
      const intervals = {};
      Object.values(consumerGoodsProducts).forEach(({ machines }) => {
        machines.forEach(machineId => {
          intervals[machineId] = setInterval(() => {
            publishMachineData(machineId, mqttClient);
          }, 2000);
        });
      });
      setPublishIntervals(intervals);
    });

    mqttClient.on('message', (topic, message) => {
      try {
        const [, machineId, messageType] = topic.split('/');
        const payload = JSON.parse(message.toString());
        
        setMachines(prevMachines => {
          const newMachines = { ...prevMachines };
          
          if (!newMachines[machineId]) {
            newMachines[machineId] = {
              id: machineId,
              status: 'Running',
              temperature: 0,
              pressure: 0,
              speed: 0,
              parts_total: 0,
              parts_good: 0,
              availability: 100,
              performance: 0,
              quality: 0,
              oee: 0,
              productLine: Object.keys(consumerGoodsProducts).find(product => 
                consumerGoodsProducts[product].machines.includes(machineId)
              ),
              historicalData: [],
              lastUpdate: new Date().toLocaleString()
            };
          }

          const machine = newMachines[machineId];

          switch (messageType) {
            case 'status':
              machine.status = payload.status;
              break;
            case 'temperature':
              machine.temperature = payload.value;
              break;
            case 'pressure':
              machine.pressure = payload.value;
              break;
            case 'speed':
              machine.speed = payload.value;
              break;
            case 'production':
              machine.parts_total = payload.total;
              machine.parts_good = payload.good;
              break;
            case 'oee':
              machine.availability = payload.availability;
              machine.performance = payload.performance;
              machine.quality = payload.quality;
              machine.oee = payload.oee;
              
              machine.historicalData = [
                ...machine.historicalData.slice(-30),
                {
                  timestamp: new Date().toLocaleTimeString(),
                  oee: payload.oee,
                  performance: payload.performance,
                  quality: payload.quality
                }
              ];
              break;
          }

          machine.lastUpdate = new Date().toLocaleString();
          return newMachines;
        });
      } catch (error) {
        console.error('Error processing message:', error);
      }
    });

    return () => {
      Object.values(publishIntervals).forEach(interval => clearInterval(interval));
      if (mqttClient) {
        mqttClient.end();
      }
    };
  }, []);
  const updateMachineData = (machine, messageType, payload) => {
    switch(messageType) {
      case 'status':
        machine.status = payload.status;
        break;
      case 'temperature':
        machine.temperature = payload.value;
        break;
      case 'pressure':
        machine.pressure = payload.value;
        break;
      case 'speed':
        machine.speed = payload.value;
        break;
      case 'production':
        machine.parts_total = payload.total;
        machine.parts_good = payload.good;
        break;
      case 'oee':
        machine.availability = payload.availability;
        machine.performance = payload.performance;
        machine.quality = payload.quality;
        machine.oee = payload.oee;
        machine.historicalData = [
          ...machine.historicalData.slice(-30),
          {
            timestamp: new Date().toLocaleTimeString(),
            oee: payload.oee,
            performance: payload.performance,
            quality: payload.quality
          }
        ];
        break;
    }
    machine.lastUpdate = new Date().toLocaleString();
  };

  const ProductLineSelector = () => (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-8">
      {Object.entries(consumerGoodsProducts).map(([product, details]) => (
        <button
          key={product}
          onClick={() => {
            setSelectedProduct(product);
            setSelectedMachine(null);
          }}
          className={`p-4 rounded-lg border-2 transition-all ${
            selectedProduct === product
              ? `border-${details.color}-500 bg-${details.color}-50`
              : 'border-gray-200 hover:border-gray-300 bg-white'
          }`}
        >
          <div className="flex items-center gap-3">
            <Package className={`h-6 w-6 text-${details.color}-500`} />
            <div className="text-left">
              <div className="font-semibold">{product}</div>
              <div className="text-sm text-gray-500">
                {details.machines.length} Machines
              </div>
            </div>
          </div>
        </button>
      ))}
    </div>
  );

  const MachineSelector = () => {
    if (!selectedProduct) return null;
    
    const productMachines = consumerGoodsProducts[selectedProduct].machines;
    const color = consumerGoodsProducts[selectedProduct].color;

    return (
      <div className="mb-8">
        <h2 className="text-xl font-semibold mb-4">{selectedProduct} Machines</h2>
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
          {productMachines.map(machineId => {
            const machine = machines[machineId];
            const isActive = machine?.status === 'Running';
            
            return (
              <button
                key={machineId}
                onClick={() => setSelectedMachine(machineId)}
                className={`p-4 rounded-lg border-2 transition-all ${
                  selectedMachine === machineId
                    ? `border-${color}-500 bg-${color}-50`
                    : 'border-gray-200 hover:border-gray-300 bg-white'
                }`}
              >
                <div className="flex items-center justify-between mb-2">
                  <Cpu className={`h-5 w-5 text-${color}-500`} />
                  <span className={`h-3 w-3 rounded-full ${
                    isActive ? 'bg-green-500' : 'bg-red-500'
                  }`} />
                </div>
                <div className="text-left">
                  <div className="font-semibold">{machineId}</div>
                  <div className="text-sm text-gray-500">
                    {isActive ? 'Running' : 'Stopped'}
                  </div>
                  {machine && (
                    <div className="text-xs text-gray-400 mt-1">
                      OEE: {machine.oee}%
                    </div>
                  )}
                </div>
              </button>
            );
          })}
        </div>
      </div>
    );
  };

// Add this new Header component
const Header = () => {
    return (
      <div className="bg-white shadow-sm mb-8">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between h-16">
            <div className="flex items-center">
              {/* Logo and Platform Name */}
              <div className="flex items-center gap-4">
                {/* Logo container with proper dimensions for PNG */}
                <div className="w-12 h-12 flex items-center justify-center">
                  <img 
                    src="/logo192.png" 
                    alt="NommasHub Logo" 
                    className="w-full h-full object-contain"
                    onError={(e) => {
                      e.target.onerror = null; // Prevent infinite loop
                      // Fallback to text if image fails to load
                      e.target.style.display = 'none';
                      e.target.parentElement.innerHTML = '<div class="w-10 h-10 bg-indigo-600 rounded-lg flex items-center justify-center"><span class="text-xl font-bold text-white">NH</span></div>';
                    }}
                  />
                </div>
                <div className="flex flex-col">
                  <h1 className="text-xl font-bold text-gray-900 leading-tight">
                    NommasHub
                  </h1>
                  <p className="text-sm text-gray-500 leading-tight">
                  Smart Manufacturing Platform                  </p>
                </div>
              </div>
            </div>
            
            {/* Optional: Additional header content */}
            <div className="flex items-center space-x-4">
              <div className="text-sm text-gray-500">
                {new Date().toLocaleDateString()}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const renderMachineDetails = () => {
    if (!selectedMachine || !machines[selectedMachine]) return null;
    
    const machine = machines[selectedMachine];
    const color = consumerGoodsProducts[selectedProduct].color;

    return (
      <>
        <Card className="mb-8">
          <CardHeader className="flex flex-row items-center justify-between">
            <div>
              <CardTitle className="text-xl">Machine {machine.id}</CardTitle>
              <p className="text-sm text-gray-500">{selectedProduct}</p>
            </div>
            <div className="flex items-center gap-4">
              <div className="text-sm text-gray-500">
                Last Update: {machine.lastUpdate}
              </div>
              <div className={`px-4 py-2 rounded-full ${
                machine.status === 'Running'
                  ? 'bg-green-100 text-green-800'
                  : 'bg-red-100 text-red-800'
              }`}>
                {machine.status}
              </div>
            </div>
          </CardHeader>
          <CardContent>
            <div className="grid grid-cols-2 md:grid-cols-4 gap-6">
              <div className="bg-gray-50 p-4 rounded-lg">
                <div className="text-sm font-medium text-gray-500">Hourly Target</div>
                <div className="mt-1 text-lg font-semibold">120 units</div>
              </div>
              <div className="bg-gray-50 p-4 rounded-lg">
                <div className="text-sm font-medium text-gray-500">Current Output</div>
                <div className="mt-1 text-lg font-semibold">{machine.parts_good} units</div>
              </div>
              <div className="bg-gray-50 p-4 rounded-lg">
                <div className="text-sm font-medium text-gray-500">Efficiency</div>
                <div className="mt-1 text-lg font-semibold">{machine.performance}%</div>
              </div>
              <div className="bg-gray-50 p-4 rounded-lg">
                <div className="text-sm font-medium text-gray-500">Quality Rate</div>
                <div className="mt-1 text-lg font-semibold">{machine.quality}%</div>
              </div>
            </div>
          </CardContent>
        </Card>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
          {[
            {
              title: 'Temperature',
              value: `${machine.temperature.toFixed(1)}°C`,
              icon: Thermometer,
              color: 'red'
            },
            {
              title: 'Pressure',
              value: `${machine.pressure.toFixed(1)} bar`,
              icon: Gauge,
              color: 'blue'
            },
            {
              title: 'Speed',
              value: `${machine.speed.toFixed(0)} RPM`,
              icon: Activity,
              color: 'green'
            },
            {
              title: 'Production Rate',
              value: `${machine.parts_good}/hr`,
              icon: Box,
              color: 'purple'
            }
          ].map((metric, index) => (
            <Card key={index}>
              <CardHeader className="flex flex-row items-center justify-between pb-2">
                <CardTitle className="text-sm font-medium">{metric.title}</CardTitle>
                <metric.icon className={`h-4 w-4 text-${metric.color}-500`} />
              </CardHeader>
              <CardContent>
                <div className="text-2xl font-bold">{metric.value}</div>
              </CardContent>
            </Card>
          ))}
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mb-8">
{/* Replace the OEE Card section in renderMachineDetails with this: */}
<Card>
  <CardHeader>
    <CardTitle className="text-xl font-semibold">OEE Performance</CardTitle>
  </CardHeader>
  <CardContent>
    <div className="grid grid-cols-3 gap-4 mb-8">
      <div className="text-center p-4 bg-blue-50 rounded-lg">
        <div className="text-lg font-semibold text-gray-600 mb-2">Availability</div>
        <div className="text-3xl font-bold text-blue-600">
          {machine.availability.toFixed(2)}%
        </div>
      </div>
      <div className="text-center p-4 bg-green-50 rounded-lg">
        <div className="text-lg font-semibold text-gray-600 mb-2">Performance</div>
        <div className="text-3xl font-bold text-green-600">
          {machine.performance.toFixed(2)}%
        </div>
      </div>
      <div className="text-center p-4 bg-purple-50 rounded-lg">
        <div className="text-lg font-semibold text-gray-600 mb-2">Quality</div>
        <div className="text-3xl font-bold text-purple-600">
          {machine.quality.toFixed(2)}%
        </div>
      </div>
    </div>
    <div className="text-center p-6 bg-indigo-50 rounded-lg">
      <div className="text-xl font-semibold text-gray-600 mb-2">Overall OEE</div>
      <div className="text-5xl font-bold text-indigo-600">
        {machine.oee.toFixed(2)}%
      </div>
    </div>
  </CardContent>
</Card>

          <Card>
            <CardHeader>
              <CardTitle>Historical Performance</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="h-80">
                <ResponsiveContainer width="100%" height="100%">
                  <LineChart data={machine.historicalData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="timestamp" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line type="monotone" dataKey="oee" stroke="#4F46E5" name="OEE" />
                    <Line type="monotone" dataKey="performance" stroke="#10B981" name="Performance" />
                    <Line type="monotone" dataKey="quality" stroke="#8B5CF6" name="Quality" />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            </CardContent>
          </Card>
        </div>
      </>
    );
  };

// Replace the existing return section with this:
return (
    <div className="min-h-screen bg-gray-100">
      <Header />
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto">
          <div className="bg-white rounded-lg shadow p-6 mb-8">
            <div className="flex justify-between items-center">
              <div>
                <h2 className="text-2xl font-bold text-gray-800">Production Overview</h2>
                <p className="text-sm text-gray-500">Real-time manufacturing metrics</p>
              </div>
              <div className={`px-4 py-2 rounded-full ${
                client?.connected 
                  ? 'bg-green-100 text-green-800' 
                  : 'bg-red-100 text-red-800'
              }`}>
                {client?.connected ? 'Connected' : 'Disconnected'}
              </div>
            </div>
          </div>
          
          <ProductLineSelector />
          <MachineSelector />
          {renderMachineDetails()}
        </div>
      </div>
    </div>
  );
    };
    
    export default ConsumerGoodsDashboard;